<template>
  <div
    class="my-2"
    v-if="!($session.exists() && $session.get('plan') !== 'FREE')"
    style="border-radius: 10px !important;min-height: 200px !important;"

  >

  </div>
</template>

<script>
export default {
  name: "AdsSquare"
}
</script>

<style scoped>

</style>
